"use strict";

angular.module('informaApp')
    .directive('repeatEnd', function () {
        return{
            restrict: "A",
            link: function (scope, element, attrs) {
                if (scope.$last === true) {
                    scope.$eval(attrs.repeatEnd);
                }
            }
        }
    });